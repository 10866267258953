import "./App.css";
import { ReactPhotoSphereViewer, LensflarePlugin } from "react-photo-sphere-viewer";
import { MarkersPlugin } from "@photo-sphere-viewer/markers-plugin";
import { VirtualTourPlugin } from "@photo-sphere-viewer/virtual-tour-plugin";
import { PlanPlugin } from "@photo-sphere-viewer/plan-plugin";
import panoJson from "./pano1.json";
function App() {
  const plugins: any = [
    [LensflarePlugin, 
      panoJson.flares],
    [
      PlanPlugin,
      panoJson.plan
    ],
    [MarkersPlugin, {}],
    [
      VirtualTourPlugin,
      {
        positionMode: "gps",
        renderMode: "3d",
        nodes: panoJson.nodes,
      },
    ],
  ];
  return (
    <div className="App">
      <header></header>
      <ReactPhotoSphereViewer
        src="Test_Pano.jpg"
        height={"100vh"}
        plugins={plugins}
        width={"100%"}
        navbar={panoJson.navbar
      }
      ></ReactPhotoSphereViewer>
    </div>
  );
}

export default App;
